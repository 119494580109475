import { HTTP, HttpErrorsHandling } from '@/utils/http-common';

// Get unread messages
export const getUnread = ({ commit }) => {
  const url = 'notifications/unread';

  return new Promise((resolve, reject) => {
    HTTP.get(url)
      .then(({ data }) => {
        if (data?.items) {
          commit('receiveNotifications', data.items);
        } else {
          commit('receiveNotifications', data);
        }

        resolve();
      })
      .catch((error) => {
        HttpErrorsHandling(error);

        reject();
      });
  });
};

// Get Notifications
export const getFirstPage = ({ state, commit }, activity_type) => {
  commit('setActivityType', activity_type);

  state.offset = null;

  state.isLoading = true;

  const url = 'notifications';

  HTTP.get(url, {
    params: {
      limit: state.limit,
      activity_type: state.activityType,
    },
  })
    .then(({ data }) => {
      commit('receiveNotifications', data.items);
      commit('setPagination', data.meta);
      commit('setUnread', data.meta);
    })
    .catch(HttpErrorsHandling)
    .then(() => {
      state.isLoading = false;
    });
};

export const getNextPage = ({ state, commit, getters }) => {
  state.isLoading = true;

  const url = 'notifications';

  HTTP.get(url, {
    params: {
      limit: state.limit,
      offset: getters.latestID,
      activity_type: state.activityType,
    },
  })
    .then(({ data }) => {
      commit('receiveNotifications', data.items);
      commit('setPagination', data.meta);
      commit('setUnread', data.meta);
    })
    .catch(HttpErrorsHandling)
    .then(() => {
      state.isLoading = false;
    });
};

// Read monification
export const toggleReadState = ({ state, commit }, noty_id) => {
  let noty = state.notifications[noty_id];

  if (noty == undefined) {
    return;
  }

  let original_state = noty.read;

  let url;

  if (original_state) {
    url = `notifications/${noty_id}/unread`;

    commit('markUnread', noty_id);
    commit('increaseUnreadCounter', noty_id);
  } else {
    url = `notifications/${noty_id}/read`;

    commit('markRead', noty_id);
    commit('decreaseUnreadCounter', noty_id);
  }

  HTTP.patch(url)
    .then(({ data: { unread_total } }) => {
      commit('changeHasNewNotifications', unread_total > 0, { root: true });
    })
    .catch((error) => {
      HttpErrorsHandling(error);

      if (original_state) {
        commit('markRead', noty_id);
        commit('decreaseUnreadCounter', noty_id);
      } else {
        commit('markUnread', noty_id);
        commit('increaseUnreadCounter', noty_id);
      }
    });
};

export const markAsRead = ({ commit }, activity_type) => {
  HTTP.patch('notifications/read_all', { activity_type })
    .then(({ data }) => {
      commit('setUnread', data);

      if (data.unread_total == 0) {
        commit('markAllAsRead');
        commit('changeHasNewNotifications', false, { root: true });
      } else {
        commit('markActivityAsRead', activity_type);
      }
    })
    .catch((error) => {
      HttpErrorsHandling(error);
    });
};
