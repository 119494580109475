import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

const state = {
  isReady: false,
  isLoading: false,

  limit: 20,

  pagination: {
    /*
    first_date
    last_date
    min_offset
    total
    */
  },
  unread: {
    unread_leaderboards: 0,
    unread_total: 0,
    unread_warning: 0,
  },
  notifications: {
    /*
    id: {
      id,
      title,
      text,
      created_at,
      read,
      activity_type,
      notification_type,
      plan,
      user,
      payload
    }
    */
  },

  activityType: '',
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
