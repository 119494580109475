export default {
  state: {
    init: 'Init',
    trialing: 'Trial',
    active: 'Active',
    past_due: 'Past due',
    canceled: 'Canceled',
    canceledEob: 'Canceled (EOB)',
    unpaid: 'Unpaid',
    resume: 'Resume',
    incomplete: 'Incomplete',
    incomplete_expired: 'Incomplete expire',
    past: 'Past',
    scheduled: 'Scheduled',
  },
};
