// const UserProfile = () => import('./layout/index.vue');
const UserProfileInfo = () => import('./info/index.vue');
// const UserProfilePayment = () => import('./payment/index.vue');
// const UserBillingHistory = () => import('./billing-history/index.vue');
// const UserPrivacy = () => import('./privacy/index.vue');
// const UserProfileSettings = () =>
//   import('@/components/profile/coach/profile/settings/index.vue');

export default [
  {
    path: 'profile',
    component: UserProfileInfo,
    name: 'user_profile_info',

    meta: {
      bodyCssClass: 'theme-bg-200',
    },
  },
  // {
  //   path: 'profile',
  //   component: UserProfile,

  //   children: [
  //     {
  //       path: '',
  //       redirect: { name: 'user_profile_info' },
  //     },
  //     {
  //       path: 'info',
  //       component: UserProfileInfo,
  //       name: 'user_profile_info',
  //       meta: { disableScrollBehavior: true },
  //     },
  //     {
  //       path: 'payment',
  //       component: UserProfilePayment,
  //       name: 'user_profile_payment',
  //       meta: { disableScrollBehavior: true },
  //     },
  //     {
  //       path: 'settings',
  //       component: UserProfileSettings,
  //       name: 'user_profile_settings',
  //       meta: {
  //         disableScrollBehavior: true,
  //       },
  //     },
  //     {
  //       path: 'billing-history',
  //       component: UserBillingHistory,
  //       name: 'user_billing_history',
  //       meta: {
  //         disableScrollBehavior: true,
  //       },
  //     },
  //     {
  //       path: 'privacy',
  //       component: UserPrivacy,
  //       name: 'user_privacy',
  //       meta: {
  //         disableScrollBehavior: true,
  //       },
  //     },
  //   ],
  // },
];
