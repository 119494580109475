export default {
  data_security_information: {
    title: 'Data security information',
    text: 'Coming soon',
  },

  not_found: {
    error: '404 error',
    sorry: "Sorry, we can't find this page.",
    go_to_main: 'Go to main page',
  },
};
