// @vue/component
export default {
  name: 'GenderControl',

  props: {
    value: String,

    autoSave: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    choiceGender(type) {
      if (type) {
        this.$emit('input', type);

        if (this.autoSave) {
          this.$store.dispatch({
            type: 'updateProfile',
          });
        }
      }
    },
  },
};
