const PurchaseForm = () => import('./form/index.vue');

export default [
  {
    path: '/p/:plan_id',
    component: PurchaseForm,
    name: 'plan_purchase_form',
    meta: {
      bodyCssClass: 'theme-payment',
    },
  },
  {
    path: '/affiliatetrack/:shared_link_hash',
    component: PurchaseForm,
    name: 'plan_purchase_shared_form',
    meta: {
      bodyCssClass: 'theme-payment',
    },
  },
];
