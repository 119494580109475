export default {
  title: 'Please activate your account',
  text: 'Please check your email for a link to activate your account.',

  resend_link: 'Resend link',
  refresh_page: 'Refresh page',
  resend_link_again: "You'll be able to resend link again in",

  expired: {
    title: 'Account Activation Link Has expired',
    text: 'To protect your account, activation links expire after 24 hours, or immediately in the event of suspicious activity.',
  },
};
