const AffiliateTrackLayout = () => import('./Layout.vue');
const AffiliateTrackMembers = () => import('./members/index.vue');

export default [
  {
    path: 'affiliate-track/:id',
    component: AffiliateTrackLayout,

    children: [
      {
        path: '',
        component: AffiliateTrackMembers,
        name: 'affiliate_track_members',

        meta: {
          bodyCssClass: 'theme-payment',
        },
      },
    ],
  },
];
