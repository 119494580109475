// @vue/component
export default {
  name: 'CookiesPolicy',

  data() {
    return {
      isCookiesAccept: false,
    };
  },

  mounted() {
    this.isCookiesAccept = localStorage.getItem('accept_cookies');
  },

  methods: {
    acceptCookies() {
      this.isCookiesAccept = true;
      localStorage.setItem('accept_cookies', true);
    },

    hide() {
      this.isCookiesAccept = true;
    },
  },
};
