export default {
  title: {
    invited: 'JOIN A TEAM',
    client: 'ATHLETE SIGN UP',
  },

  subtitle: {
    invited_1:
      'If you’ve received a team invite, create your account below and we’ll direct you to join the team. ',
    invited_2:
      'There’s no cost for contributing to an existing team account and you’ll have the option to upgrade if you want to create your own standalone programming in the future.',
    client: 'Become a Hard Worker',
  },

  agreement: {
    text: 'By creating this account, I agree to the following HWPO',
    terms_link: 'Terms & Conditions',
    privacy_link: 'Privacy Policy',
    error: 'You must agree before submitting.',
  },

  continue: 'Continue',
  log_in: 'Log in',
  already_an_athlete: 'Already an athlete?',

  rogue: {
    subtitle_1:
      'Enter name, date of birth and chosen password. Your email has been pre-populated from Rogue and cannot be changed.',
    subtitle_2:
      'Programming will be waiting for you inside the HWPO Training App with these login credentials.',
    subtitle_3: 'SHOW UP, WORK HARD and MAKE YOURSELF PROUD!',
  },
};
