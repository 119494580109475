import Store from '@/store/store.js';

const Layout = () => import('./layout/index.vue');
const Daily = () => import('./daily/index.vue');
const DailyFull = () => import('./daily-full/index.vue');

export default [
  {
    path: '/leaderboards',
    component: Layout,

    beforeEnter: (to, from, next) => {
      if (Store.getters.isUserCoach) {
        next();
      } else {
        next({ name: 'main', replace: true });
      }
    },

    children: [
      {
        path: '',
        redirect: { name: 'daily-leaderboards' },
      },

      {
        path: 'daily',
        component: Daily,
        name: 'daily-leaderboards',
        meta: {
          typeLeaderboards: 'leaderboard',
        },
      },

      {
        path: 'daily/:id/',
        component: DailyFull,
        name: 'daily-leaderboard',
        meta: {
          bodyCssClass: 'daily-leaderboard',
          disableScrollBehavior: true,
        },
        props: true,
      },

      {
        path: 'daily/:id/comments/:result_id?',
        component: DailyFull,
        name: 'daily-leaderboard-comments',
        meta: {
          bodyCssClass: 'daily-leaderboard',
          disableScrollBehavior: true,
        },
        props: ({ params }) => ({ ...params, show_comments: true }),
      },

      {
        path: 'challenges',
        component: Daily,
        name: 'challenges-leaderboards',
        meta: {
          typeLeaderboards: 'challenge',
        },
      },

      {
        path: 'challenges/:id/',
        component: DailyFull,
        name: 'challenges-leaderboard',
        meta: {
          bodyCssClass: 'daily-leaderboard',
          disableScrollBehavior: true,
        },
        props: true,
      },

      {
        path: 'challenges/:id/comments/:result_id?',
        component: DailyFull,
        name: 'challenges-leaderboard-comments',
        meta: {
          bodyCssClass: 'daily-leaderboard',
          disableScrollBehavior: true,
        },
        props: ({ params }) => ({ ...params, show_comments: true }),
      },
    ],
  },
];
