export default {
  planContent: {
    free: 'Your program starts today.',

    fixedPlan: {
      free: 'The program will be waiting for you in the app immediately.',
      paid: 'You will be charged today and the program will be waiting for you in the app immediately after purchase.',
    },

    startDate: {
      trial: [
        '*You will not be charged today. Your trial begins',
        'and your first payment will be',
        'days later.',
      ],
      noTrial: [
        'You will be charged immediately on',
        'when your program starts.',
      ],
    },

    noStartDate: {
      trial: [
        'You will not be charged today. Your trial begins today, and your first payment will be in',
        'days.',
      ],
      noTrial: {
        pastLaunchDate:
          'Your program starts today and you will be charged immediately.',
        futureLaunchDate: [
          'When the program launches on',
          'you will be charged immediately.',
        ],
      },
    },
  },
};
