export default {
  label: {
    name: 'Name the benchmark workout',
    describe: 'Describe this benchmark workout',
    score_type: 'Score Type',
    ordering: 'Leaderboard Ordering',
    reps_per_round: 'Reps per Round',
    number_of_score_intervals: 'Set the number of score intervals',
    leaderboard_calculation: 'Leaderboard calculation',
    customise_leaderboard_calculation: 'Customise leaderboard calculation',
    time_cap: 'Set Time Cap',
    total_rounds: 'Total Rounds',
  },
  placeholder: {
    name: 'e.g. Who’s faster',
    describe: 'Let your athlete know what to do...',
    score_type: 'Select a Metric',
    ordering: 'E.g. Faster is better',
    number_of_score_intervals: 'Select a number',
    leaderboard_calculation: 'Select a type',
    customise_leaderboard_calculation: 'Select a value',
  },
  tooltip: {
    reps_per_round:
      'How many reps are in each round? We’ll need this to correctly calculate the score.',
    customise_leaderboard_calculation:
      "Add the number of 'best' intervals to sum and take to the leaderboard. E.g. the best 3 score intervals.",
    custom_type:
      'Tick the interval boxes you wish to sum and use as the overal leaderboard score.',
    total_rounds:
      'Set the total no. of rounds in the workout - used to calculate any required time penalties. Tip: For uneven reps per round, set 0 rounds and add total required reps.',
    time_cap_reps_per_round:
      "How many reps are in each round? We'll need this to calculate any required time penalty.",
  },
};
