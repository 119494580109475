const ActivityTypes = {
  content_warnings: 'content_warnings',
  chat_mention: 'chat_mention',
};

const NotificationTypes = {
  // coach - content warnings
  coach_warning_fixed_plan: 'coach_warning_fixed_plan',

  coach_warning_automated_plan: 'coach_warning_automated_plan',
  coach_danger_automated_plan: 'coach_danger_automated_plan',

  coach_warning_bespoke_plan: 'coach_warning_bespoke_plan',
  coach_danger_bespoke_plan: 'coach_danger_bespoke_plan',

  coach_warning_one_many_plan: 'coach_warning_one_many_plan',
  coach_danger_one_many_plan: 'coach_danger_one_many_plan',

  // coach - Mentions
  coach_leaderboard_chat_mention: 'coach_leaderboard_chat_mention',
};

const NotificationActions = {
  // *************************************
  // COACH
  // *************************************
  // Content Warnings
  [NotificationTypes.coach_warning_fixed_plan]: {
    main: 'training_calendar',
    sub: ['client_profile', 'plan_downloads'],
  },
  [NotificationTypes.coach_warning_automated_plan]: {
    main: 'plan_schedule',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_danger_automated_plan]: {
    main: 'plan_schedule',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_warning_bespoke_plan]: {
    main: 'bespoke_schedule',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_danger_bespoke_plan]: {
    main: 'bespoke_schedule',
    sub: ['training_calendar', 'client_profile', 'subscriber_list'],
  },
  [NotificationTypes.coach_warning_one_many_plan]: {
    main: 'one_many_schedule',
    sub: ['subscriber_list'],
  },
  [NotificationTypes.coach_danger_one_many_plan]: {
    main: 'one_many_schedule',
    sub: ['subscriber_list'],
  },

  // Coach Mentions
  [NotificationTypes.coach_leaderboard_chat_mention]: {
    main: 'view_leaderbord',
    sub: [],
  },
};

const UnreadCounters = {
  content_warnings: 'unread_warning',
  chat_mention: 'unread_leaderboards',
};

function getUnreadCounterByType(type) {
  return UnreadCounters[type];
}

export {
  ActivityTypes,
  NotificationTypes,
  NotificationActions,
  UnreadCounters,
  getUnreadCounterByType,
};
