const UserProfile = () => import('./Layout.vue');
const UserBillingPaymentMethods = () =>
  import('@/components/profile/user/billing/payment/index.vue');
const UserBillingHistory = () =>
  import('@/components/profile/user/billing/billing-history/index.vue');
const UserBillingMyPurchases = () =>
  import('@/components/profile/user/billing/my-purchases/index.vue');
const UserBillingPromoCodes = () =>
  import('@/components/profile/user/billing/promo-codes/index.vue');

export default [
  {
    path: 'billing',
    component: UserProfile,
    // Named Route 'user_billing' has a default child route. When navigating to this named route (:to="{name: 'user_billing'"), the default child route will not be rendered.
    // Remove the name from this route and use the name of the default child route for named links instead.
    // name: 'user_billing',

    children: [
      {
        path: '',
        redirect: { name: 'user_billing_my_purchases' },
      },
      {
        path: 'my_purchases',
        component: UserBillingMyPurchases,
        name: 'user_billing_my_purchases',
        meta: {
          disableScrollBehavior: true,
        },
      },
      {
        path: 'payment_methods',
        component: UserBillingPaymentMethods,
        name: 'user_billing_payment_methods',
        meta: {
          disableScrollBehavior: true,
        },
      },
      {
        path: 'billing_history',
        component: UserBillingHistory,
        name: 'user_billing_history',
        meta: {
          disableScrollBehavior: true,
        },
      },
      {
        path: 'promo_codes',
        component: UserBillingPromoCodes,
        name: 'user_billing_promo_codes',
        meta: {
          disableScrollBehavior: true,
        },
      },
    ],
  },
];
