const MyMedia = () => import('./Layout.vue');
const MyMediaIndex = () => import('./index/index.vue');
const MyMediaEdit = () => import('./edit/index.vue');
const MyMediaShow = () => import('./show/index.vue');

export default [
  {
    path: 'media',
    component: MyMedia,

    children: [
      {
        path: '',
        component: MyMediaIndex,
        name: 'media_library',
      },
      {
        path: ':type',
        component: MyMediaShow,
        name: 'type_media_library',
        props: true,
      },
      {
        path: 'edit/:id',
        component: MyMediaEdit,
        name: 'media_edit',
        props: true,
      },
    ],
  },
];
