// @vue/component
export default {
  name: 'PoweredBy',

  data() {
    return {
      isMobile: false,
    };
  },

  mounted() {
    this.mq = window.matchMedia('(max-width: 767.98px)');
    this.mq.addListener(this.updateMatches);
    this.updateMatches();
  },

  beforeDestroy: function () {
    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    updateMatches() {
      this.isMobile = this.mq.matches;
    },
  },
};
