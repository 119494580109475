import Store from '@/store/store.js';

const Layout = () => import('./layout/index.vue');
const List = () => import('./list/index.vue');

export default [
  {
    path: '/feed',
    component: Layout,

    beforeEnter: (to, from, next) => {
      if (Store.getters.isUserCoach) {
        next();
      } else {
        next({ name: 'main', replace: true });
      }
    },

    children: [
      {
        path: '',
        redirect: { name: 'feed_overview' },
      },
      {
        path: 'overview',
        name: 'feed_overview',
        component: List,
        props: { type: 'overview' },
      },

      {
        path: 'warnings',
        name: 'feed_warnings',
        component: List,
        props: { type: 'content_warnings' },
      },

      {
        path: 'leaderboards',
        name: 'feed_leaderboards',
        component: List,
        props: { type: 'chat_mention' },
      },
    ],
  },
];
