import DatePicker from 'vue2-datepicker';

// @vue/component
export default {
  name: 'BirthdateControl',

  components: {
    DatePicker,
  },

  props: {
    /**
     * Value of v-model
     * @type {string}
     */
    value: String,

    /**
     * Disable auto save
     * @type {boolean}
     */
    noAutoSave: Boolean,

    /**
     * Disable input
     * @type {boolean}
     */
    disabled: Boolean,

    /**
     * Is show error
     * @type {boolean}
     */
    showError: Boolean,

    /**
     * Is field required
     * @type {boolean}
     */
    required: Boolean,

    /**
     * Minimal age allowed
     * @type {number}
     * @default 18
     */
    minAge: {
      type: Number,
      default: 18,
    },
  },

  computed: {
    defaultValue() {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();

      return new Date(year - this.minAge, month, day);
    },

    birthdate: {
      get: function () {
        if (this.value) {
          return new Date(this.$moment(this.value, 'DD/MM/YYYY'));
        }

        return null;
      },
      set: function (newValue) {
        if (newValue) {
          let newBirthdate = this.$moment(newValue, 'MM/DD/YYYY').format(
            'DD/MM/YYYY'
          );

          this.$emit('input', newBirthdate);

          this.$emit('setNewBirthdate', newBirthdate);

          if (!this.noAutoSave) {
            this.$store.dispatch({
              type: 'updateProfile',
            });
          }
        }
      },
    },
  },

  methods: {
    disabledDate(date) {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();

      let after = new Date(year - 100, month, day);
      let before = new Date(year - this.minAge, month, day);

      return (
        date.getTime() < after.getTime() || date.getTime() > before.getTime()
      );
    },
  },
};
