import { i18n } from '@/i18n';
import { HTTP } from '@/utils/http-common';

const Layout = () => import('@/components/auth/layout/index.vue');

const SignUp = () => import('@/components/auth/sign-up/index.vue');
const Confirmation = () => import('@/components/auth/confirmation/index.vue');
const SignIn = () => import('@/components/auth/sign-in/index.vue');
const ForgotPassword = () =>
  import('@/components/auth/forgot-password/index.vue');
const ResetPassword = () =>
  import('@/components/auth/reset-password/index.vue');
const AcceptInvitation = () =>
  import('@/components/auth/accept-invitation/index.vue');
const AthleteInvitation = () =>
  import('@/components/auth/athlete-invitation/index.vue');
const SuccessPage = () => import('@/components/auth/success/index.vue');

import Store from '@/store/store.js';

export default [
  {
    path: '/onboarding',
    component: Layout,

    beforeEnter: (to, from, next) => {
      // change locale if browser language is supported
      const systemLanguage = navigator.language.split('-')[0];

      if (
        i18n.availableLocales.includes(systemLanguage) &&
        !localStorage.getItem('client_language')
      ) {
        i18n.locale = systemLanguage;
        HTTP.defaults.headers.common['Language'] = systemLanguage;
      }

      next();
    },

    children: [
      {
        path: '',
        name: 'onboarding',
        redirect: { name: 'onboarding-signin' },
      },

      {
        path: 'sign_in',
        name: 'onboarding-signin',
        component: SignIn,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'forgot_password',
        name: 'onboarding-forgot-password',
        component: ForgotPassword,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ path: '/', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: 'reset_password',
        component: ResetPassword,
        name: 'onboarding-reset-password',
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ path: '/', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: 'sign_up',
        name: 'onboarding-coaching-signup',
        component: SignUp,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },

      {
        path: 'accept-invitation/:token',
        name: 'onboarding-accept-invitation',
        component: AcceptInvitation,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ path: '/', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: 'athlete-invitation/:token',
        component: AthleteInvitation,
        props: true,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          if (Store.state.authenticated) {
            next({ name: 'main', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: '/success',
        name: 'onboarding-success-page',
        component: SuccessPage,
        props: true,
        meta: {
          publicPage: false,
          hideFooter: true,
        },
        beforeEnter: (to, from, next) => {
          let popup_storage = Store.state.currentUser.popup_storage;
          if (to.params.role == 'coach' && !!to.params.token) {
            popup_storage = Store.state.currentUser.popup_storage;
          }
          if (popup_storage?.includes('sign-up-welcome-page')) {
            next({ name: 'choose_plan_type', replace: true });
          } else {
            next();
          }
        },
      },

      {
        path: '/confirmation',
        name: 'onboarding-confirmation',
        component: Confirmation,
        meta: {
          publicPage: true,
          hideFooter: true,
        },
      },
    ],
  },
];
