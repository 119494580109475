import Store from '@/store/store.js';

const CoachProfile = () => import('./Layout.vue');
// const CoachProfileSettings = () => import('./settings/index.vue');
const CoachProfileCoaches = () => import('./coaches/index.vue');
const CoachProfilegeneral = () => import('./general/index.vue');
const CoachProfilePassword = () => import('./password/index.vue');

export default [
  {
    path: 'profile',
    component: CoachProfile,
    // Named Route 'coach_profile' has a default child route. When navigating to this named route (:to="{name: 'coach_profile'"), the default child route will not be rendered.
    // Remove the name from this route and use the name of the default child route for named links instead.
    // name: 'coach_profile',

    children: [
      {
        path: '',
        redirect: { name: 'coach_profile_general' },
      },

      {
        path: 'general',
        component: CoachProfilegeneral,
        name: 'coach_profile_general',
        meta: {
          disableScrollBehavior: true,
        },
      },

      {
        path: 'password',
        component: CoachProfilePassword,
        name: 'coach_profile_password',

        meta: {
          disableScrollBehavior: true,
        },
      },

      // {
      //   path: 'settings',
      //   component: CoachProfileSettings,
      //   name: 'coach_profile_settings',

      //   beforeEnter: function (to, from, next) {
      //     if (Store.getters.isFinancialAviable) {
      //       next();
      //     } else {
      //       next({ name: '404', replace: true });
      //     }
      //   },

      //   meta: {
      //     disableScrollBehavior: true,
      //   },
      // },

      {
        path: 'coaches',
        component: CoachProfileCoaches,
        name: 'coach_profile_coaches',

        beforeEnter: function (to, from, next) {
          if (Store.getters.isFinancialAviable) {
            next();
          } else {
            next({ name: '404', replace: true });
          }
        },

        meta: {
          disableScrollBehavior: true,
        },
      },
    ],
  },
];
