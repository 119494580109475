export default {
  view_full: 'View full leaderboard',

  placeholder: {
    title: 'There are no results to display.',
    text: 'Change filters or wait for athletes to update their scores.',
  },

  daily_placeholder: {
    title: 'There are no leaderboards to display',
    text: 'Link scores into your program schedules to drive real-time leaderboards.',
  },

  challenges_placeholder: {
    title: 'There are no benchmark workout leaderboards to display',
    text: "Insert benchmark workouts across your programs and we'll collate all the scores into a single leaderboard. Great for cross-program interaction and competition. Re-use benchmark workouts over time and track progression.",
  },
};
