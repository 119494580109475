import create from './create.js';
import form from './form.js';
import list from './list.js';
import item from './item.js';
import results from './results.js';

export default {
  layout: {
    title: 'Leaderboards',
    nav_items: {
      first: 'Daily',
      second: 'Benchmark workouts',
    },
  },

  link_leaderboard: {
    title: 'Link leaderboard',
    tooltip:
      'Link a leaderboard to this part by adding a score. This will apply ONLY to this program and is a one-off leaderboard. If you want athletes from different programs to compete against each other, or you want to track progress over time for the same workout, please use Benchmark workouts’ instead.',
  },

  scoring_details: 'Scoring details',

  comments: {
    overall_title: 'Overall leaderboard comments ({count})',
  },

  header: {
    last_updated: 'Last updated',
    comments: 'Comments',
    overall_comments: 'Overall comments',
  },

  create,
  form,
  list,
  item,
  results,
};
