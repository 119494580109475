const Layout = () => import('./Layout.vue');
const List = () => import('./list/index.vue');
const AllPlans = () => import('./list/all-plans/index.vue');

const ProgramForm = () => import('./form/index.vue');
const ProgramView = () => import('./view/index.vue');
const ProgramViewClients = () => import('./view/clients/index.vue');
const ProgramViewSettings = () => import('./view/settings/index.vue');
const ProgramSchedule = () => import('./schedule/index.vue');
const FormChooseType = () => import('./choose_type/index.vue');

export default [
  {
    path: 'programs',
    component: Layout,

    children: [
      {
        path: '',
        component: List,
        name: 'my_plans',
        redirect: { name: 'my_plans_active' },
        meta: {
          myPlans: true,
        },

        children: [
          {
            path: '',
            redirect: { name: 'my_plans_active' },
          },

          {
            path: 'active',
            component: AllPlans,
            name: 'my_plans_active',
            meta: {
              plansType: 'active',
            },
          },

          {
            path: 'active/ready/',
            component: AllPlans,
            name: 'my_plans_active_with_welcome_popup',
            meta: {
              plansType: 'active',
              showWelcomePopup: true,
            },
          },

          {
            path: 'attention',
            component: AllPlans,
            name: 'my_plans_attention',
            meta: {
              plansType: 'attention',
            },
          },
        ],
      },

      {
        path: 'choose-plan-type',
        name: 'choose_plan_type',
        component: FormChooseType,
      },

      {
        path: 'create',
        component: ProgramForm,
        name: 'coach_program_create',
        props: true,
        meta: {
          bodyCssClass: 'theme-bg-200',
          hideFooter: true,
        },
      },

      {
        path: ':plan_id',
        component: ProgramView,
        props: true,

        children: [
          {
            path: '',
            name: 'coach_program_view',
            redirect: { name: 'coach_program_view_clients' },
          },
          {
            path: 'clients',
            name: 'coach_program_view_clients',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'active' },
          },

          {
            path: 'in-trials',
            name: 'coach_program_view_in_trials',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'in_trial' },
          },

          {
            path: 'scheduled',
            name: 'coach_program_view_scheduled',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'scheduled' },
          },

          {
            path: 'pending',
            name: 'coach_program_view_pending',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'pending' },
          },

          {
            path: 'pending-cancel',
            name: 'coach_program_view_pending_cancel',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'pending_cancel' },
          },

          {
            path: 'past',
            name: 'coach_program_view_past',
            component: ProgramViewClients,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
            props: { listType: 'past' },
          },

          {
            path: 'settings',
            name: 'coach_program_view_settings',
            component: ProgramViewSettings,
            meta: {
              disableScrollBehavior: true,
              bodyCssClass: 'theme-bg-200',
            },
          },
        ],
      },

      {
        path: ':plan_id/edit',
        component: ProgramForm,
        name: 'coach_program_edit',
        props: true,
        meta: {
          hideFooter: true,
          bodyCssClass: 'theme-bg-200',
        },
      },

      {
        path: ':plan_id/schedule',
        component: ProgramSchedule,
        name: 'coach_program_schedule',
        props: true,
        meta: {
          hideFooter: true,
        },
      },
    ],
  },
];
