import ActionCable from 'actioncable';

// ActionCable.startDebugging();

let cable;

function connect() {
  // console.log('connect');

  let token = window.localStorage.getItem('access_token');

  cable = ActionCable.createConsumer(
    `${import.meta.env.VITE_WS_BASE}?token=${token}`
  );
}

function disconnect() {
  // console.log('disconnect');

  if (cable) {
    cable.disconnect();
  }
}

function subscribe(name, received, params) {
  // console.log('subscribe');

  let channel = {
    channel: name,
  };

  if (params) {
    channel = { ...channel, ...params };
  }

  return cable.subscriptions.create(channel, { received });
}

function unsubscribe(channel) {
  // console.log('unsubscribe');

  channel.unsubscribe();
}

export { connect, disconnect, subscribe, unsubscribe };
