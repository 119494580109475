export default {
  item: {
    are_you_sure:
      "Are you sure you want to remove this media item?<br>This can't be undone.",
    yes: ', Remove media',
    no: ', Keep this media',
    no_btn: 'No',
    yes_btn: 'Yes',
    edit_btn: 'Edit Media',
    delete_btn: 'Delete Media',
  },
  lib: {
    files: {
      title: 'Upload new media',
      text: 'Click or drag items into the blue box to upload.',
      file_name: 'File Name',
      type: 'Type',
      date: 'Date',
    },
    links: {
      youtube_modal: 'Paste the YouTube url here and press enter to upload.',
      placeholder: {
        title: 'Link to a YouTube video',
        text: 'Paste the YouTube video URL above and press enter',
      },
      name: 'Name',
      date: 'Date',
      video_label: 'Videos',
      photo_label: 'Photos',
      file_label: 'Files',
      unsaved_message:
        'There are unsaved changes. Do you want to leave anyway?',
    },
  },

  title: 'Add new media',
  youtube_link: 'Youtube Link',
  my_computer: 'My Computer',
  library: 'Library',
  search_placeholder: 'Search media in library…',
  filters: 'Filters',
  done_btn: 'Done',
  cancel_btn: 'Cancel',
  add_btn: 'Add media',
};
