import create from './create';
import form from './form';
import list from './list';
import item from './item';
import results from './results';
import index from './index.json';

export default {
  create,
  form,
  list,
  item,
  results,

  ...index,
};
