export default {
  years: 'y/o',
  buttons: {
    hide: 'Hide',
    expand: 'Expand',
  },
  columns: {
    place: 'Place',
    name: 'Name',
    result: 'Result',
  },
};
