import { mapGetters } from 'vuex';
import PoweredBy from '@/shared/powered-by/index.vue';

// @vue/component
export default {
  name: 'AppFooter',

  computed: {
    ...mapGetters(['isUserCoach']),
  },

  components: {
    PoweredBy,
  },
};
