export default {
  additional_profile_info_popap: {
    save_btn: 'Save',
  },
  affiliated_gym_select: {
    no_results: 'No elements found. Consider changing the search query.',
    placeholder: 'Affiliated Gym',
  },
  app_header: {
    system_warning: {
      warning_item: {
        text_1: 'You have missed a program payment -',
        text_2: 'to resolve.',
        link: 'click here',
      },
    },
  },

  attachments_field: {
    add_btn: 'Add File or Media',
  },

  attachments_list: {
    title: 'Media',
    title_title: 'Title',
    files: 'Files',
    show_all_btn: 'Show all',
    show_less_btn: 'Show less',
    media: 'Media',
  },

  day_comments: {
    title: 'Comments',
    cancel_btn: 'Cancel',
    save_comment_btn: 'Save Comment',
    reply_btn: 'Reply',
    edit_btn: 'Edit',
    delete_btn: 'Delete',

    delete_comment_modal: {
      text: 'Please confirm that you want to delete this message.',
      title: 'Are you 100% sure?',
      ok_btn: 'Yes, Delete Message',
      cancel_btn: 'Cancel',
    },

    collapse_btn: 'Collapse',
    expand_btn: 'Expand',
    resource_type_score_title: 'Share some love',
    resource_type_score_text:
      "Leave a comment on this person's result for them to see.",
    resource_type_other_title: 'Start a conversation',
    resource_type_other_text:
      'Share some love with the people on this leaderboard!',
    succesfully_sent: 'Your message was succesfully sent.',
  },

  media_crop: {
    new_upload_btn: 'Upload new cover',
    back_btn: 'Back',
    cancel_btn: 'Cancel',
    save_btn: 'Save crop',
  },

  add_payment_method_modal: {
    required_text:
      '*Required fields. This information is stored directly and securely in Stripe. Address information will appear on your invoices and receipts.',
    cancel_btn: 'Cancel',
    successfully_updated: 'Card was successfully updated.',
    successfully_added: 'Card was successfully added.',

    card_form: {
      card_number_label: 'Card Number',
      expiry_date_label: 'Expiry Date',
      cvc_label: 'CVС',
      country_label: 'Country',
      country_required: 'Country required',
      state_label: 'State / Province',
      state_required: 'State required',
      state_placeholder: 'Select',
      address_line_1_label: 'Address line 1',
      address_line_2_label: 'Address line 2',
      city_label: 'City / Town',
      city_placeholder: 'Enter city / town',
      city_required: 'City / Town required',
      postal_code_label: 'ZIP / Postal code',
      postal_code_required: 'ZIP / Postal code required',
      phone_number_label: 'Phone number',
      phone_number_placeholder: 'Your phone number',
    },
  },

  choose_program_option_modal: {
    title: 'copy to program variation',
    label: 'Program variation',
    default_option: 'Select program variation',
    cancel_btn: 'Cancel',
    copy_btn: 'Copy',
  },

  gowod_add_protocol: {
    pre: 'Pre WOD',
    post: 'Post WOD',
    close_btn: 'close',
    add_btn: 'Add',
    title: 'add gowod protocol',
  },

  gowod_already_added_modal: {
    title: 'Gowod',
    text: 'protocol has already been added to the training day.',
    ok_btn: 'Ok',
    pre: 'Pre WOD',
    post: 'Post WOD',
  },

  gowod_set_up_modal: {
    title: 'Set up GOWOD links',
    text_1: 'Chosen template include <b>GOWOD</b> links.',
    text_2:
      'To enable the links, please select the corresponding program name in <b>GOWOD</b>.',
    label: 'Program name in gowod',
    ignore_parts_btn: 'Ignore GOWOD parts',
    cancel_btn: 'Cancel',
    save_btn: 'Save',
    insert: '& Insert',
    notify: 'Saved Changes',
  },

  template_name_modal: {
    label: 'Template name',
    placeholder: 'E.g. warmup template',
    feedback_max: 'Template name must have at most {max_length} letters.',
    feedback_min: 'Template name must have at least {min_length} letters.',
    feeddack_required: 'Template name is required.',
    close_btn: 'close',
    rename_btn: 'Rename template',
    create_btn: 'Create template',
    create_day: 'Create a new day template',
    create_week: 'Createa new week(s) template',
    create: 'Create a new template',
  },

  facility_details_modal: {
    title: 'Set your facility details',

    description:
      'Please enter your facility name, this can be your gym or business name, city and country.',

    facility: {
      label: 'Business/facility name',
      placeholder: 'Your business/facility name',

      validation: {
        empty: 'Business/facility name is required',
        long: 'Business/facility name can contain 1-100 characters',
      },
    },

    country: {
      label: 'Country',
      placeholder: 'Select a country',

      validation: {
        empty: 'Country is required',
      },
    },

    city: {
      label: 'City',
      placeholder: 'Enter city',

      validation: {
        empty: 'City is required',
        long: 'City can contain 1-100 characters',
      },
    },

    next_btn: 'Next',
    save_btn: 'Save',
    generate_btn: 'Generate new link',
  },

  generate_new_link_modal: {
    title: 'Generate new link',
    description:
      'Are you 100% sure you want to generate a new shared link? The current link will be deactivated, so nobody else can subscribe via it.',

    ok_btn: 'yes, generate new link',
    close_btn: 'close',
  },

  multiselect_field: {
    no_elements: 'No elements found',
    maximum_text:
      'Maximum of { max } options can be selected. Please remove some of the previously selected options before selecting another option.',
  },

  page_filter: {
    filters_btn: 'Filters',
    clear_btn: 'Clear Filters',
    apply_btn: 'Apply Filters',
    datarange: {
      all: 'All',
      set_age: 'Set age range',
      placeholder: 'e.g. 20',
      placeholder_2: 'e.g. 40',
      feedback: 'Must be between 13 and 99',
    },
  },

  page_sidebar: {
    form_day: {
      pre_wod: 'Pre wod',
      post_wod: 'Post wod',
      each_gowod:
        'Each GOWOD protocol may only be added once in a program variation.',
      title_placeholder: 'Add a title - e.g. Warmup.',
      title_required: 'Title is required.',
      title_min: 'Title must have at least 2 letters.',
      title_max: 'Title must have at most 64 letters.',
      tips_placeholder:
        "Daily video as you want to write it in the program's schedule",
      textatea_placeholder_1:
        'What’s involved? Describe what you want your athlete to do.',
      textatea_placeholder_2:
        'What’s involved? Describe what’s involved in your part here.',
      day_successfully_updated: 'Day was successfully updated.',
      part_successfully_updated: 'Part was successfully updated.',
    },

    performance_field: {
      performance: 'Performance',
      seen: 'Seen',
    },

    view_day: {
      daily_video: 'DAILY VIDEO',
      pre_wod: 'PRE WOD',
      post_wod: 'POST WOD',
      attachments: 'Attachments',
    },
  },

  plan_detail: {
    back_btn: 'Back to programs',
    copied: '✓ Copied to Clipboard',

    components: {
      cover: {
        title: 'Upload Cover Media',
      },

      info: {
        free: 'Free',
        launch_date: 'Launch Date',
      },
    },
  },

  powered_by: {
    alt: 'Powered by FITR',
  },

  select_gowod: {
    default_option: 'Select Program name',
  },

  toggle_switch: {
    off: 'Off',
    on: 'On',
  },

  video_player: {
    video_processed: 'Video is being processed',
  },

  view_mode_switcher: {
    compact: 'Compact',
    detailed: 'Detailed',
  },

  date_range: {
    confirm: 'OK',

    shortcuts: {
      one_day: '1d',
      one_week: '1w',
      one_month: '1m',
      one_year: '1y',
      six_months: '6mo',
      mtd: 'Mtd',
      qtd: 'Qtd',
      all: 'All',
    },
  },
};
