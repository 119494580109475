import { mapState, mapGetters } from 'vuex';

import UserAvatar from '@/shared/user-avatar/index.vue';

const NotificationsIndicator = () =>
  import('@/components/feed/indicator/index.vue');

const SystemWarning = () =>
  import('@/shared/app-header/system-warning/index.vue');

import HelpDropdown from '@/shared/app-header/help-dropdown/index.vue';
import LanguageSwitcher from '@/shared/language-switcher/index.vue';

const checkSubstring = function (str, subStrings) {
  if (
    subStrings.some(function (v) {
      return str.indexOf(v) >= 0;
    })
  ) {
    return true;
  } else {
    return false;
  }
};

// @vue/component
export default {
  name: 'AppHeader',

  components: {
    UserAvatar,
    NotificationsIndicator,
    SystemWarning,
    HelpDropdown,
    LanguageSwitcher,
  },

  data() {
    return {
      isShowMenu: false,
      isMobileLayout: false,
    };
  },

  computed: {
    activeAccount() {
      return this.currentUser;
    },

    isCurrentUser() {
      return this.authenticated;
    },

    currentUserName() {
      if (this.isAccountOwner || !this.isUserCoach) {
        return this.activeAccount.full_name;
      } else {
        return this.activeAccount.private_title;
      }
    },

    defaultAvatar() {
      return this.currentUserName.charAt(0).toUpperCase();
    },

    isProgrammingLinkActive() {
      let routes = [
        'coach/programs',
        'challenges',
        'leaderboards',
        'templates',
        'media',
      ];

      return checkSubstring(this.$route.path, routes);
    },

    ...mapState(['currentUser', 'authenticated']),
    ...mapGetters(['isUserCoach', 'isAccountOwner']),
  },

  mounted: function () {
    this.$root.$on('hide-user-menu', this.close);

    this.mq = window.matchMedia('(max-width: 991.98px)');

    this.updateMatches();

    this.mq.addListener(this.updateMatches);
  },

  beforeDestroy: function () {
    this.$root.$off('hide-user-menu', this.close);

    if (this.mq) {
      this.mq.removeListener(this.updateMatches);
    }
  },

  methods: {
    updateMatches() {
      this.isMobileLayout = this.mq.matches;
    },

    close: function () {
      this.toggleBodyClass('removeClass', 'stop-scrolling');
      this.isShowMenu = false;
    },

    toggleMenu() {
      if (this.isShowMenu) {
        this.toggleBodyClass('removeClass', 'stop-scrolling');
      } else {
        this.toggleBodyClass('addClass', 'stop-scrolling');
      }

      this.isShowMenu = !this.isShowMenu;
    },

    coachMenuShowed() {
      this.$root.$emit('coach-menu-showed');

      if (this.isShowMenu) {
        this.close();
      }
    },

    singOut() {
      this.toggleBodyClass('removeClass', 'stop-scrolling');

      this.$store.dispatch({
        type: 'signout',
      });
    },

    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    hideCoachDropdownMenu() {
      if (this.$refs.coachDropdownMenu) {
        this.$refs.coachDropdownMenu.hide(true);
      }
    },
  },
};
