<template>
  <div class="container static-page">
    <h1>{{ $t('pages.data_security_information.title') }}</h1>
    <p>{{ $t('pages.data_security_information.text') }}</p>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style src="@/assets/styles/_static-pages.scss" lang="scss" scoped></style>
