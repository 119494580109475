const UserProfile = () => import('./Layout.vue');
const UserSettingsDefaultUnits = () => import('./default-units/index.vue');
const UserProfileSettings = () =>
  import('@/components/profile/coach/profile/settings/index.vue');

export default [
  {
    path: 'settings',
    component: UserProfile,
    // Named Route 'user_settings' has a default child route. When navigating to this named route (:to="{name: 'user_settings'"), the default child route will not be rendered.
    // Remove the name from this route and use the name of the default child route for named links instead.
    // name: 'user_settings',

    children: [
      {
        path: '',
        redirect: { name: 'user_settings_default_units' },
      },
      {
        path: 'default_units',
        component: UserSettingsDefaultUnits,
        name: 'user_settings_default_units',
        meta: {
          disableScrollBehavior: true,
        },
      },
      {
        path: 'emails',
        component: UserProfileSettings,
        name: 'user_settings_emails',
        meta: {
          disableScrollBehavior: true,
        },
      },
    ],
  },
];
