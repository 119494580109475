import Pages from '@/components/pages/Pages.vue';
import DataSecurity from '@/components/pages/DataSecurity.vue';

export default [
  {
    path: '/pages',
    component: Pages,
    children: [
      {
        path: '',
        component: DataSecurity,
      },
      {
        path: 'data_security',
        component: DataSecurity,
        name: 'data_security',
        meta: { publicPage: true },
      },
    ],
  },
];
