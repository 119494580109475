import Vue from 'vue';
import en from '@/lang/common';
import fr from '@/lang/fr/common';
import es from '@/lang/es/common';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en', // установка локализации по умолчанию
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
    es,
  },
});
