import Store from '@/store/store.js';

/*******************************************************************************
 ** USER
 *******************************************************************************/
import UserLayout from './user/Layout.vue';

// Modules
import UserRoutes from './user/profile/routes.js';
import UserPurchasesRoutes from './user/purchases/routes.js';
import UserSettingsRoutes from './user/settings/routes.js';
import UserBillingRoutes from './user/billing/routes.js';
import UserAffiliateTrack from './user/affiliate-track/routes.js';

/*******************************************************************************
 ** COACH
 *******************************************************************************/
import CoachLayout from './coach/Layout.vue';

// Modules
import ProfileRoutes from './coach/profile/routes.js';
import CoachMediaRoutes from './coach/media/routes.js';
import ClientsRoutes from './coach/clients/routes.js';
import CoachCalendarRoutes from './coach/calendar/routes.js';
import CoachAthleteCalendarRoutes from './coach/athlete_calendar/routes.js';
import CoachProgramsRoutes from './coach/programs/routes.js';
import CoachTemplatesRoutes from './coach/templates/routes.js';
import CoachBenchmarksRoutes from './coach/benchmarks/routes.js';

import CoachChallengesRoutes from './coach/challenges/routes.js';

const CoachCouponsPages = () => import('./coach/coupons/index.vue');

export default [
  {
    path: '/profile',
    name: 'profile_main',

    beforeEnter: (to, from, next) => {
      if (Store.state.currentUser.role === 'user') {
        next({ name: 'user_profile_info', replace: true });
      } else {
        next({ name: 'coach_profile_general', replace: true });
      }
    },
  },

  {
    path: '/user',
    component: UserLayout,

    beforeEnter: (to, from, next) => {
      if (Store.state.currentUser.role === 'coach') {
        next({ path: '/coach', replace: true });
      } else {
        next();
      }
    },

    children: [
      {
        path: '',
        redirect: { path: 'user_profile_info' },
      },
      ...UserRoutes,
      ...UserPurchasesRoutes,
      ...UserSettingsRoutes,
      ...UserBillingRoutes,
      ...UserAffiliateTrack,
    ],
  },

  // COACH
  {
    path: '/coach',
    component: CoachLayout,

    beforeEnter: (to, from, next) => {
      if (!to.meta.publicPage && Store.state.currentUser.role === 'user') {
        next({ path: '/user', replace: true });
      } else {
        next();
      }
    },

    children: [
      {
        path: '',
        redirect: { name: 'coach_profile_general' },
      },

      {
        path: 'coupons',
        name: 'coach_profile_coupons',
        component: CoachCouponsPages,
        beforeEnter: function (to, from, next) {
          if (Store.getters.isFinancialAviable) {
            next();
          } else {
            next({ name: '404', replace: true });
          }
        },
      },

      ...CoachProgramsRoutes,
      ...CoachMediaRoutes,
      ...ProfileRoutes,
      ...ClientsRoutes,
      ...CoachCalendarRoutes,
      ...CoachAthleteCalendarRoutes,
      ...CoachTemplatesRoutes,
      ...CoachBenchmarksRoutes,
      ...CoachChallengesRoutes,
    ],
  },
];
