export default {
  nav: {
    programs: 'Programs',
    clients: 'Athletes',
    templates: 'Templates',
    leaderboards: 'Leaderboards',
    challenges: 'Benchmark workouts',
    media: 'Media',
    coupons: 'Coupons',
    help_centre: 'Help Center',
    settings: 'Settings',
    profile: 'Profile',
    feed: 'Feed',
    billing: 'Billing',
    log_out: 'Log Out',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
  },
};
