export default {
  buttons: {
    back_leaderboard: 'Back to Leaderboards',
    back_benchmark: 'Back to Benchmark workouts',
    hide_all_results: 'Hide all results',
    expand_all_results: 'Expand all results',
    load_more: 'Load more results',
  },

  searc_placeholder: 'Search athletes…',
  no_results: {
    title: 'There are no results to display.',
    text: 'Change filters or wait for athletes to update their scores.',
  },

  filters: {
    gender: {
      label: 'Gender',
      options: {
        first: 'All',
        second: 'Male',
        third: 'Female',
        fourth: 'Other',
      },
    },
    age: {
      label: 'Age',
      options: {
        first: 'All',
        second: 'Set age range',
      },
    },
    adjusted: {
      label: 'Scaled',
      options: {
        first: 'Show RX & Scaled',
        second: 'Show only RX',
        third: 'Show only Scaled',
      },
    },
    score_status: {
      label: 'Score status',
      options: {
        first: 'Visible',
        second: 'Hidden by coach',
      },
    },

    results: {
      label: 'Show athlete’s',
      options: {
        first: 'Best result',
        second: 'Latest result',
      },
    },

    gymType: {
      label: 'Gym Type',
      options: {
        first: 'Home or Garage Gym',
        second: 'Unaffiliated Gym',
        third: 'Affiliated Gym',
      },
    },
  },

  alerts: {
    score_hidden: 'Score was hidden.',
    score_restored: 'Score was restored.',
  },
};
