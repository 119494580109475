export default {
  header: {
    title: 'Feed',
    new: 'NEW',
  },

  popup: {
    title: 'My Feed',
    view_full: 'View Full Feed',
    empty: 'No new notifications',
  },

  layout: {
    title: 'My Feed',
    placeholder: {
      title: 'Your Feed',
      text: "Once you're up and running activity about your coaching business will show here.",
      link: 'Create a Training Program',
    },
    tooltip: {
      are_read: 'Notifications are read',
      mark_as_read: 'Mark all as read',
    },
  },

  list: {
    title: 'Oops.',
    alt_title: 'Hmmm.',
    leaderboards:
      'Updates such as comments on leaderboards will appear here over time.',
    coach:
      'Once you start coaching clients on training programs, updates will feed here over time.',
  },

  notification: {
    tooltip: {
      mark_as_unread: 'Mark as unread',
      mark_as_read: 'Mark as read',
    },
  },
};
