const Layout = () => import('./layout/index.vue');
const ClientsWithPrograms = () => import('./all-clients/index.vue');
const ClientProfileLayout = () => import('./client-profile/index.vue');
const ClientPrograms = () => import('./client-profile/programs/index.vue');

export default [
  {
    path: 'clients',
    component: Layout,

    children: [
      {
        path: '',
        redirect: { name: 'clients_with_programs' },
      },
      {
        path: 'active',
        component: ClientsWithPrograms,
        name: 'clients_with_programs',
        meta: {
          clientsType: 'active',
        },
      },
      {
        path: 'in-trials',
        component: ClientsWithPrograms,
        name: 'trial_clients',
        meta: {
          clientsType: 'in_trial',
        },
      },
      {
        path: 'scheduled',
        component: ClientsWithPrograms,
        name: 'scheduled_clients',
        meta: {
          clientsType: 'scheduled',
        },
      },
      {
        path: 'pending',
        component: ClientsWithPrograms,
        name: 'pending_clients',
        meta: {
          clientsType: 'pending',
        },
      },
      {
        path: 'pending-cancel',
        component: ClientsWithPrograms,
        name: 'pending_cancel_clients',
        meta: {
          clientsType: 'pending_cancel',
        },
      },
      {
        path: 'past',
        component: ClientsWithPrograms,
        name: 'past_clients',
        meta: {
          clientsType: 'past',
        },
      },
    ],
  },

  {
    path: 'clients/:athlete_id',
    component: ClientProfileLayout,
    props: true,

    children: [
      {
        path: '',
        redirect: { name: 'show_client_programs' },
      },
      {
        path: 'programs',
        component: ClientPrograms,
        name: 'show_client_programs',
        props: true,
        meta: {
          bodyCssClass: 'athlete-plans',
        },
      },
    ],
  },
];
