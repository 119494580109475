export default {
  payment: {
    new_method: 'add payment information',
    update_method: 'Update payment information',
    btn_add_method: 'add billing information',
    btn_update_method: 'Save',
  },
  perfomance: {
    about: {
      title: 'Review your athlete’s session notes',
      text_1:
        'If your athlete has added session notes, you’ll see something like this:',
      text_2:
        'If you can’t see any session notes, the athlete has left this day blank.',
      text_3:
        'To leave feedback, click into the desired day and scroll to the comment section.',
      dont_show: 'Don’t show this pop-up again',
    },
  },
  plan_types: {
    fixed: {
      point_1:
        'Upon download, every athlete will receive the full training schedule.',
      point_2:
        "You will need to pre-load the program's schedule for the duration of the program before you can sell it to athletes.",
      point_3:
        'Each athlete will be able to choose when to start the program - e.g. download on a Friday, start the following Monday.',
      point_4:
        'Manage one central training schedule safe in the knowledge training will be delivered automatically to your athletes on download.',
      point_5:
        'Ideal for programs where there is a definitive start and end goal.',
    },
    membership: {
      point_1:
        "Regardless of when any athlete subscribes, they'll start at the very first session you ever built. This could mean you have athletes at different stages of the program at any one time.",
      point_2:
        'You don’t need to load all the training to start selling this program, just the first week or so. Add future programming as time progresses.',
      point_3:
        "Athletes will always have 7 days visibulity into the future. Build in advance and we'll let them see it when it's time.",
      point_4:
        'Ideal for training cylces or rehabilitation programs where you want athletes to begin at the very start of what you’ve built, not at today’s date.',
    },
    individual: {
      point_1:
        'This program is for athletes who need to receive 100% bespoke training.',
      point_2: 'You do NOT need a bepsoke program per bespoke athlete.',
      point_3:
        "Why? As each athlete joins, you'll have the ability to add bespoke training to their individual training schedule.",
      point_4:
        'Create one bespoke program and invite all your bespoke athletes to it. You can even create coupon codes to offer different athletes different payment terms on the same program.',
    },
    one_many: {
      point_1:
        'Every athlete will get the same programming on the same calendar date. One training schedule to update, unlimited athletes.',
      point_2:
        'Utilise our personal record feature to allow you to customise the workouts to each individual’s ability.',
      point_3:
        'Save time by managing one central training schedule safe in the knowledge training will be delivered to your athletes when they need it.',
      point_4:
        'Ideal for where you want a whole group of people to receive the same core programming, in a calendar format, such as gym wide programming.',
    },
  },
  schedule_hint: {
    title: 'Helpful hint',
    fixed:
      "Now to build your program's content. Take your time, you can save programs as draft and come back to them. The perfect program takes time.",
    fixed_2:
      "Once you're ready to unleash it to the world, head to Step 3 - Review & Publish.",
    membership:
      'You only need to create the first week of the training schedule before you can publish the program.',
    membership_2:
      "You can add more weeks over time once you've published the program and athletes are onboard.",
    else_1:
      "After publishing this program you'll be able to create the training your athletes will follow.",
    else_2:
      "After publishing, we'll show you how to sign up athletes to the program too.",
    dont_show: 'Don’t show me this again',
  },
  paste_sections_hint: {
    title: "'Paste to' options",
    text: "You now have two options on how to paste the programming you've just copied:",
    day: '- сlick this icon to paste everything into the same day',
    structure:
      '- click this icon to paste the training whilst keeping its copied structure',
    dont_show: 'Don’t show me this message again',
  },
  user_info: {
    invited: {
      with_token:
        'We’ll now link you into the training programs area so you can get started.',
      else: {
        text_1:
          "We'll take you to your feed now where, if you've been invited to join an existing team, you'll see an invite ready for you to accept.",
        text_2: "Don't see an invite in your feed?",
        text_3:
          "Make sure the person inviting you is using the same email as you've signed up with. If they haven't, they'll need to re-invite you to the team.",
      },
    },
    standalone:
      "Now you're set up, have a play around creating training programs and signing up athletes. We're always here to help in live chat if you need.",
    start_button: 'Start Using Fitr Training',
  },
  custom_program: {
    title: 'Do you need another custom program?',
    text: 'Remember you can add multiple athletes to a single custom program and give each totally independent training to follow; saving you from creating a new program for every athlete!',
    cancel: 'Cancel',
    continue: 'Yes, continue',
    dont_show: "Don't show me this warning again.",
  },
  sub_success_resume: {
    title: 'Subscription successfully resumed',
    text: 'Billing will resume on your usual payment date,',
    dont_show: 'Do not show me again',
    ok: 'Ok',
  },
  rogue_resume: {
    title: {
      retry: 'Retry missed payment',
      resume: 'Resume subscription',
    },
    retry: 'To retry the missed payment, please visit your account on',
    resume: 'To resume this subscription, please visit your account on',
    close: 'Ok',
  },
};
