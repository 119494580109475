<template>
  <div class="wrap d-flex flex-column">
    <AppHeader></AppHeader>

    <div
      class="
        content
        d-flex
        justify-content-center
        align-items-center
        flex-column
        m-auto
      "
    >
      <div class="thumb">
        <span>404</span>
        <img
          src="@/assets/images/not_found.svg"
          alt="Create and sell your first training plan"
        />
      </div>
      <h2>{{ $t('pages.not_found.error') }}</h2>
      <p>{{ $t('pages.not_found.sorry') }}</p>
      <div class="actions text-center">
        <a :href="gotToMain" class="btn btn-primary">{{
          $t('pages.not_found.go_to_main')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/shared/app-header/index.vue';

export default {
  components: {
    AppHeader,
  },

  data: () => ({}),

  computed: {
    gotToMain: function () {
      return import.meta.env.VITE_APP_BASE;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  margin-bottom: 48px;

  span {
    position: absolute;
    top: 72px;
    left: 50%;
    color: #db2b44;
    font-size: 64px;
    transform: translate(-50%, -50%);
  }
}
h2 {
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: $font-weight-normal;
}
p {
  margin-bottom: 40px;
  color: #91939f;
}
</style>
